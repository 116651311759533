<template>

    <DotPattern/>

    <div class="container gm-padding-y">
        <h3 class="!leading-[1.5] font-bold text-3xl text-center mb-4">
            Meet our <span class="text-callout-primary">hallucination-proof</span> workflow builder.
        </h3>

        <p class="text-center">
            Our voice agents don't follow scripts - They can handle any conversation.
        </p>

        <div class="mt-10 flex flex-col lg:flex-row-reverse border-secondary border-2 overflow-hidden rounded-xl">
            <div class="bg-secondary text-white grow p-8 shadow-xl lg:w-72">
                <p class="font-bold lg:text-2xl">
                    Our simple to use
                    no-code builder
                    enables you to model any kind of call scenario in minutes.
                </p>

                <p class="text-white mt-4">
                    Collect caller information like names, addresses, business information, email addresses, and more.
                    Schedule appointments, transfer calls, send webhooks - The possibilities are endless.
                </p>

            </div>

            <div class="max-w-4xl mx-auto rounded-lg overflow-hidden shadow 0 flex items-center">
                <LazyRendering @render="render">
                    <video src="/static/videos/workflows.mp4"
                           ref="video"
                           autoplay
                           muted
                           loop
                           playsinline
                           class="w-full"/>
                </LazyRendering>
            </div>

        </div>
    </div>

</template>
<script setup>
import DotPattern from "@/Components/DotPattern.vue";
import {nextTick, onMounted, ref} from "vue";
import LazyRendering from "@/Components/LazyRendering.vue";

const video = ref()

const render = (() => {
    nextTick(() => {
        if (video.value) {
            // set playback speed to 3x
            video.value.playbackRate = 3
            // play
            try {
                video.value?.play()
            } catch (e) {
                // console.error(e)
            }
        }
    })
})
</script>
